<template>
  <el-main class="main">
    <div class="title">其他</div>
    <div class="sep" />
    <div class="content">
      <div
        class="row"
        v-for="file in files"
        :key="file.index"
        @click="handleClick(file.url)"
      >
        <el-image
          class="row-pdf"
          fit="cover"
          :src="require('@/assets/pdf.png')"
        ></el-image>
        <span class="row-content">{{ file.index }}）{{ file.name }}</span>
        <el-image
          class="row-download"
          fit="cover"
          :src="require('@/assets/download.png')"
          @click="handleDownload(file.url, file.name)"
        ></el-image>
      </div>
    </div>
  </el-main>
</template>

<script>
import store from "@/store";
export default {
  name: "others",
  data() {
    return {
      files: [
        {
          index: 1,
          name: "知识产权公共项目申报指南",
          url: "https://hndip-file-public.oss-cn-shanghai.aliyuncs.com/%E5%85%B6%E5%AE%83%E6%9C%8D%E5%8A%A1/1%E7%9F%A5%E8%AF%86%E4%BA%A7%E6%9D%83%E5%85%AC%E5%85%B1%E9%A1%B9%E7%9B%AE%E7%94%B3%E6%8A%A5%E6%8C%87%E5%8D%97.pdf",
        },
        {
          index: 2,
          name: "高校知识产权转化",
          url: "https://hndip-file-public.oss-cn-shanghai.aliyuncs.com/%E5%85%B6%E5%AE%83%E6%9C%8D%E5%8A%A1/2%E9%AB%98%E6%A0%A1%E7%9F%A5%E8%AF%86%E4%BA%A7%E6%9D%83%E8%BD%AC%E5%8C%96.pdf",
        },
        {
          index: 3,
          name: "企业知识产权贯标辅导示范",
          url: "https://hndip-file-public.oss-cn-shanghai.aliyuncs.com/%E5%85%B6%E5%AE%83%E6%9C%8D%E5%8A%A1/3%E4%BC%81%E4%B8%9A%E7%9F%A5%E8%AF%86%E4%BA%A7%E6%9D%83%E8%B4%AF%E6%A0%87%E8%BE%85%E5%AF%BC%E7%A4%BA%E8%8C%83.pdf",
        },
        {
          index: 4,
          name: "知识产权托管",
          url: "https://hndip-file-public.oss-cn-shanghai.aliyuncs.com/%E5%85%B6%E5%AE%83%E6%9C%8D%E5%8A%A1/4%E7%9F%A5%E8%AF%86%E4%BA%A7%E6%9D%83%E6%89%98%E7%AE%A1.pdf",
        },
        {
          index: 5,
          name: "知识产权政策宣传、培训",
          url: "https://hndip-file-public.oss-cn-shanghai.aliyuncs.com/%E5%85%B6%E5%AE%83%E6%9C%8D%E5%8A%A1/5%E7%9F%A5%E8%AF%86%E4%BA%A7%E6%9D%83%E6%94%BF%E7%AD%96%E5%AE%A3%E4%BC%A0%E3%80%81%E5%9F%B9%E8%AE%AD.pdf",
        },
      ],
    };
  },
  beforeRouteEnter(to, from, next) {
    store.commit("business/SET_ACTIVEINDEX", { activeIndex: "3" });
    next();
  },
  methods: {
    handleClick(url) {
      window.open(url, "_blank");
    },
    handleDownload(url, name) {
      console.log("handleDownload");
      let link = document.createElement("a");
      link.download = name + ".pdf";
      link.href = url + "?response-content-type=application/octet-stream";
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style lang="stylus" scoped>
.main {
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 72px;

  .title {
    font-size: 30px;
    font-family: PingFang-SC-Heavy, PingFang-SC;
    font-weight: 800;
    color: #2C53B0;
    line-height: 42px;
    text-align: center;
  }

  .sep {
    height: 2px;
    background: #2C53B0;
    margin-top: 14px;
  }

  .content {
    padding-top: 12px;
    padding-bottom: 100px;

    .row {
      padding-top: 13px;
      padding-bottom: 13px;
      border-bottom: 1px solid #F3F5FB;
      word-break: break-all
      &:hover {
        background-color: #F3F5FB;
        cursor: pointer;

        .row-download {
          visibility: visible;
        }

        .row-content {
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          color: #2C53B0;
        }
      }

      .row-pdf {
        width: 20px;
        height: 20px;
        vertical-align: middle;
        margin-left: 12px;
      }

      .row-content {
        font-size: 16px;
        font-family: PingFang-SC;
        font-weight: 400;
        color: #222222;
        line-height: 24px;
        vertical-align: middle;
        padding-left: 12px;
      }

      .row-download {
        width: 20px;
        height: 20px;
        float: right;
        vertical-align: middle;
        visibility: hidden;
        margin-right: 15px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>